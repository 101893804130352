import React from 'react';
import { Link } from 'gatsby';
import { Container, Row, Col } from 'react-bootstrap';
import { AiOutlineStar } from '@react-icons/all-files/ai/AiOutlineStar';
const Footer = () => (
  <footer id="footer" className="gray-bg">
    <div className="bd-footer-area pt-50 pb-60">
      <Container>
        <Row>
          <Col lg={6} sm={6}>
            <div className="bd-footer-widget bd-footer-widget-six footer-col-5 mb-30">
              <div className="bd-footer-info">
                <h3>Les Pins en Ré</h3>
                <h4 className="theme-color">Île de Ré</h4>
                <p className="mb-30">
                  Villa de standing, contemporaine, classée 5 étoiles
                  <br />
                  <AiOutlineStar />
                  <AiOutlineStar />
                  <AiOutlineStar />
                  <AiOutlineStar />
                  <AiOutlineStar />
                </p>
              </div>
            </div>
          </Col>
          <Col lg={6} sm={6}>
            <div className="bd-footer-widget bd-footer-widget-six footer-col-8 mb-30">
              <h5 className="bd-footer-widget-title bd-footer-widget-title-six">
                Contact
              </h5>
              <div className="bd-footer-info">
                <div className="bd-footer-info-item bd-footer-info-item-six mb-10">
                  <h6>Téléphone : </h6>
                  <a href="tel:06 12 01 61 96">06 12 01 61 96</a>
                </div>
                <div className="bd-footer-info-item bd-footer-info-item-six mb-10">
                  <h6>Email : </h6>
                  <a href="mailto:jf.douay@gmail.com">jf.douay@gmail.com</a>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
    <div className="bd-copyright-area-six">
      <Container>
        <Row>
          <Col lg={{ span: 6, order: 2 }} className="order-lg-1">
            <div className="bd-copyright-six pb-10 text-center text-lg-start">
              <p className="m-0">
                © {new Date().getFullYear()}, Les pins en Ré
              </p>
            </div>
          </Col>
          <Col lg={{ span: 6, order: 2 }} className="order-lg-2">
            <div className="bd-copyright-six pb-10 text-lg-end text-center">
              <ul>
                <li>
                  <Link to="/politique-de-confidentialite/">
                    Politique de Confidentialité
                  </Link>
                </li>
                <li>
                  <Link to="/mentions-legales/">Mentions Légales</Link>
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  </footer>
);

export default Footer;
