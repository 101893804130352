import React from 'react';
import { string, object, arrayOf } from 'prop-types';
import { useSiteMetadata } from '../hooks/use-site-metadata';

const Seo = ({ title, description, pathname, children }) => {
  const {
    title: defaultTitle,
    description: defaultDescription,
    siteUrl,
  } = useSiteMetadata();

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    url: `${siteUrl}${pathname || ``}`,
  };

  return (
    <>
      <title>{seo.title}</title>
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:url" content={seo.url} />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:image" content={seo?.image || ``} />
      <meta name="twitter:creator" content={seo.twitterUsername || ``} />
      {children}
    </>
  );
};

Seo.defaultProps = {
  lang: `fr`,
  meta: [],
  description: ``,
  title: `Pins en ré`,
};

Seo.propTypes = {
  description: string,
  lang: string,
  meta: arrayOf(object),
  title: string.isRequired,
  pathname: string,
  children: object,
};

export default Seo;
